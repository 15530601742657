import React, { useState } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";

import { Button, Link as GlobalLink } from "@global";
import { checkIsMicroSite, isBrowser } from "@util/helper";
import { useCheckScreenWidth, useEventListener } from "@util/hooks";
import { Container, DesktopContainer, MobileContainer } from "@util/standard";
import { useHeaderQuery } from "@api";
import { useStore } from "@state/store";
import ImageLinkMenu from "@components/nav/imageLinkMenu";
import { isSanityImageMenu, isSanityLink } from "@util/types";
import {
  appPaths,
  assets,
  MOBILE_BREAKPOINT,
  SMALL_LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import LinkMenu from "@components/nav/linkMenu";
import MobileMenu from "@components/nav/mobileMenu";
import SearchBar from "@components/nav/searchBar";

interface Props {
  searchBarOpenOnLoad?: boolean;
}

const SearchIcon = styled.img`
  height: 15px;
  width: 15px;
  margin: auto;
  margin-left: 30px;
  cursor: pointer;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 18px;
    height: 18px;
  }
`;

const Logo = styled.img`
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 96px;
    margin: auto;
  }
`;

const StyledLink = styled(Link)`
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: auto 0;
  }
`;

const SearchContainer = styled(Container)<{ visible?: boolean }>`
  display: block;
  position: fixed;
  top: 0px;
  opacity: 0;
  ${({ visible }) => visible && "opacity: 1; top: 100px; "};
  transition: all 0.2s;
  width: 100%;
  z-index: 2;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const Header = ({ searchBarOpenOnLoad }: Props) => {
  const headerData = useHeaderQuery();

  const [hasScrolled, setHasScrolled] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const { openDrawer, user } = useStore();

  const checkForScroll = () => {
    if (isBrowser()) {
      if (window.scrollY > 1) {
        setHasScrolled(true);
        return;
      }
      setHasScrolled(false);
    }
    return;
  };

  useEventListener("scroll", checkForScroll);

  if (headerData == null) {
    return null;
  }
  const [currentDropDownIndex, setCurrentDropDownIndex] = useState<number>();

  const { logo, links, pageLinks, buttonText } = headerData;
  const { isMobileWidth } = useCheckScreenWidth();

  const CtaButton = () => {
    const text = () => {
      if (user) return "Back to Dashboard";
      if (isMobileWidth) return "Enquiry";
      if (buttonText) return buttonText;
      return "Product Enquiry";
    };

    const handleClick = () => {
      if (user) {
        return navigate(appPaths.orders);
      }
      return openDrawer();
    };

    return (
      <Button
        onClick={handleClick}
        theme="base"
        linkText={text()}
        padding={isMobileWidth ? "12px 29px" : "16px 25px"}
      />
    );
  };

  const isMicrosite = checkIsMicroSite();
  return (
    <>
      <Container
        position="fixed"
        top="0px"
        width="100%"
        backgroundColor={searchOpen || hasScrolled ? "white" : "transparent"}
        zIndex={3}
      >
        <Container
          width="100%"
          padding="30px 2%"
          mobilePadding="15px"
          justifyContent="space-between"
        >
          <Container>
            {logo && logo.asset && (
              <StyledLink to="/">
                <Logo alt={logo.altText ?? "Header logo"} src={logo.asset.url as string} />
              </StyledLink>
            )}
            {!isMicrosite && (
              <DesktopContainer customHideBreakPoint={SMALL_LAPTOP_BREAKPOINT} margin="0 0 0 40px">
                {links?.map((link, index) => {
                  if (isSanityImageMenu(link) && link.menuLinks) {
                    return (
                      <div
                        onClick={() => setCurrentDropDownIndex(index)}
                        key={link._key}
                        style={{ margin: "auto 6% auto 0" }}
                      >
                        <ImageLinkMenu
                          menuTitle={link.menuTitle}
                          data={link.menuLinks}
                          margin="auto"
                          visible={currentDropDownIndex === index}
                        />
                      </div>
                    );
                  }
                  if (isSanityLink(link)) {
                    return (
                      <GlobalLink {...link} color="navy" margin="auto 6% auto 0" key={link._key} />
                    );
                  }
                  return null;
                })}
              </DesktopContainer>
            )}
          </Container>
          <Container>
            {pageLinks && !isMicrosite && (
              <DesktopContainer>
                <LinkMenu data={pageLinks} />
              </DesktopContainer>
            )}
            <CtaButton />
            {!isMicrosite && (
              <>
                <DesktopContainer customHideBreakPoint={SMALL_LAPTOP_BREAKPOINT}>
                  <SearchIcon src={assets.search} onClick={() => setSearchOpen(!searchOpen)} />
                </DesktopContainer>
                <MobileContainer customVisibleBreakPoint={SMALL_LAPTOP_BREAKPOINT}>
                  <MobileMenu data={headerData} />
                </MobileContainer>
              </>
            )}
          </Container>
        </Container>
      </Container>

      {!isMicrosite && (
        <SearchContainer visible={searchOpen}>
          <SearchBar
            visible={searchOpen}
            openOnLoad={searchBarOpenOnLoad}
            setVisible={setSearchOpen}
          />
        </SearchContainer>
      )}
    </>
  );
};

export default Header;
