import React, { useEffect, useState } from "react";
import firebase from "gatsby-plugin-firebase";
import { navigate } from "gatsby";
import { toast } from "react-toastify";

import { GridContainer, Input } from "@util/standard";
import { Button } from "@global";
import { useStore } from "@state/store";
import { appPaths } from "@util/constants";
import { isBrowser } from "@util/helper";
import { User } from "@state/types";

export default function LoginForm() {
  if (!isBrowser()) return null;
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);

  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const { user, setUser } = useStore();

  useEffect(() => {
    if (user) {
      setLoading(false);
      navigate(appPaths.orders);
    }
  }, [user]);

  const handleSetUser = (uid: string) => {
    firestore
      .collection("users")
      .doc(uid)
      .get()
      .then(snapshot => {
        const userData = snapshot.data() as User;

        if (userData == null) {
          console.log("an error occurred when trying to get user data");
          return;
        }

        const user = {
          ...userData,
          uid,
        };

        return setUser(user);
      })
      .catch((error: any) => console.log(error));
  };

  const handleLoginClick = () => {
    setLoading(true);
    auth
      .signInWithEmailAndPassword(formData.email, formData.password)
      .then((results: any) => {
        if (results == null || results.user == null) {
          setLoading(false);
          toast.error("An error occurred while trying to log in");
          return;
        }

        return handleSetUser(results.user.uid);
      })
      .catch((_error: any) => {
        setLoading(false);
        toast.error("Login Error: please check that your details are correct");
      });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleLoginClick();
    }
  };

  return (
    <GridContainer rowGap={20} width="90%" margin="auto" repeat={1} tabletRepeat={1}>
      <h2>Login</h2>
      <Input
        type="email"
        placeholder="Email address"
        required
        onChange={e => setFormData({ ...formData, email: e.target.value })}
      />
      <Input
        type="password"
        placeholder="Password"
        required
        onChange={e => setFormData({ ...formData, password: e.target.value })}
        onKeyDown={handleKeyDown}
      />
      <Button
        loading={loading}
        disabled={loading}
        margin="20px 0"
        linkText="Log in"
        onClick={handleLoginClick}
      />
    </GridContainer>
  );
}
