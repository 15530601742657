import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import firebase from "gatsby-plugin-firebase";

import Header from "./header";
import Footer from "./footer";
import { GlobalStyle, Main } from "@styles/globalStyles";
import { AgeRestrictionPopup, Drawer } from "@global";
import { useStore } from "@state/store";
import { colors } from "@util/constants";
import EnquiryForm from "@components/forms/enquiryForm";
import LoginForm from "@components/forms/loginForm";
interface Props {
  children: ReactNode;
  hideFooterCtas?: boolean;
  searchBarOpenOnLoad?: boolean;
}

const StyledToastContainer = styled(ToastContainer)`
  width: 400px !important;
  .Toastify__toast-theme--colored.Toastify__toast--success {
    background: ${colors.navy};
  }
  .Toastify__toast-icon {
    display: none;
  }
  .Toastify__toast-container--bottom-right {
    bottom: 30px !important;
    right: 30px !important;
  }
`;

function Layout(props: Props) {
  const { children, hideFooterCtas, searchBarOpenOnLoad } = props;
  const { drawerVisible, closeDrawer, drawerForm, user, loggedIn, clearSession } = useStore();

  useEffect(() => {
    if (user) {
      closeDrawer();
    }
    if (user && !loggedIn) {
      firebase
        .auth()
        .signOut()
        .then(_result => {
          clearSession();
        });
    }
  }, [user, loggedIn]);

  return (
    <div>
      <GlobalStyle />
      <Header searchBarOpenOnLoad={searchBarOpenOnLoad && searchBarOpenOnLoad} />
      <Drawer
        backgroundColor="white"
        visible={drawerVisible}
        onClose={closeDrawer}
        placement="right"
      >
        {drawerForm === "enquiry" ? <EnquiryForm /> : <LoginForm />}
      </Drawer>
      <AgeRestrictionPopup />
      <Main>{children}</Main>
      <Footer hideCtas={hideFooterCtas} />
      <StyledToastContainer position="bottom-right" theme="colored" autoClose={3000} />
    </div>
  );
}

export default Layout;
