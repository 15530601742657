import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Maybe, SanityImageLink } from "@graphql-types";
import { AbsoluteContainer, Container, P } from "@util/standard";
import { Image, Link } from "@global";
import { assets, colors, colorsRGB } from "@util/constants";

interface Props {
  menuTitle?: Maybe<string> | undefined;
  data: Maybe<SanityImageLink>[];
  margin?: string;
  visible?: boolean;
}

const MenuWrapper = styled(AbsoluteContainer)`
  top: 0px;
  left: 0px;
  padding: 16% 15% 11% 15%;
  width: 70%;
  z-index: -1;
  background-color: white;

  text-align: center;
  a {
    width: 80%;
    white-space: initial;
  }
`;

const NoImagePlaceHolder = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${colors.grey};
  margin: auto;
`;

const Arrow = styled.img<{ menuOpen?: boolean }>`
  margin-left: 9px;
  transform: rotateZ(0deg);

  ${({ menuOpen }) => menuOpen && `transform: rotateZ(180deg);`};
  transition: transform 0.2s;
`;

function ImageLinkMenu({ menuTitle, data, margin, visible }: Props) {
  if (data == null) return null;
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (visible) {
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
    }
  }, [visible]);

  return (
    <>
      <Container onClick={() => setMenuOpen(!menuOpen)} margin={margin}>
        <P width="max-content" cursor="pointer" fontWeight="medium" margin="16px auto">
          {menuTitle}
          <Arrow menuOpen={menuOpen} src={assets.arrowDown} />
        </P>
        {menuOpen && (
          <MenuWrapper>
            {data.map(link => {
              if (link == null || link.link == null) return null;
              return (
                <Container flexDirection="column" width="100%" key={link._key}>
                  <Link {...link.link}>
                    <Container
                      height="80px"
                      width="100px"
                      flexDirection="column"
                      margin="0 auto 30px auto"
                    >
                      {link.image ? <Image data={link.image} /> : <NoImagePlaceHolder />}
                    </Container>
                    <P fontWeight="medium">{link.link.linkText}</P>
                  </Link>
                </Container>
              );
            })}
          </MenuWrapper>
        )}
      </Container>
      {menuOpen && (
        <AbsoluteContainer
          top="0px"
          left="0px"
          height="100vh"
          width="100vw"
          backgroundRGBColor={colorsRGB.black(0.4)}
          zIndex={-2}
          onClick={() => setMenuOpen(false)}
        />
      )}
    </>
  );
}

export default ImageLinkMenu;
