import React, { useState } from "react";
import styled from "styled-components";

import { Maybe, SanityNestedLink } from "@graphql-types";
import { AbsoluteContainer, Container, P } from "@util/standard";
import { Link } from "@global";
import { assets, colors } from "@util/constants";

interface Props {
  data: Maybe<SanityNestedLink>;
}

const Arrow = styled.img<{ menuOpen?: boolean }>`
  margin-left: 15px;
  transform: rotateZ(0deg);

  ${({ menuOpen }) => menuOpen && `transform: rotateZ(180deg);`};
  transition: transform 0.2s;
`;

const StyledLink = styled(Link)<{ isLast?: boolean | null | undefined }>`
  background-color: ${colors.white};
  padding: 15px;
  ${({ isLast }) => isLast && `border-radius: 0 0 10px 10px;`};
`;

function LinkMenu({ data }: Props) {
  if (data == null) return null;
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <Container
      margin="auto 15px auto auto"
      position="relative"
      backgroundColor={menuOpen ? "white" : "transparent"}
      onClick={() => setMenuOpen(!menuOpen)}
      cursor="pointer"
      borderRadius={10}
    >
      {data.menuTitle && (
        <P padding="0 15px" fontWeight="medium">
          {data.menuTitle}
          <Arrow src={assets.arrowDown} menuOpen={menuOpen} />
        </P>
      )}
      {data.menuLinks && menuOpen && (
        <AbsoluteContainer
          bottom="-40px"
          height="100%"
          width="100%"
          flexDirection="column"
          backgroundColor="white"
        >
          {data.menuLinks.map((link, index) => {
            if (link == null) return null;

            const isLast =
              data.menuLinks && data.menuLinks[0] && index === data.menuLinks.length - 1;
            return <StyledLink {...link} color="navy" isLast={isLast} key={link._key} />;
          })}
        </AbsoluteContainer>
      )}
    </Container>
  );
}

export default LinkMenu;
