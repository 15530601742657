import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Container } from "@util/standard";
import { assets, colors, colorsRGB, fontWeights, TABLET_BREAKPOINT } from "@util/constants";
import { navigate } from "gatsby";
import { useStore } from "@state/store";
import { useEventListener } from "@util/hooks";

interface Props {
  visible?: boolean | undefined;
  setVisible?: Dispatch<SetStateAction<boolean>>;
  openOnLoad?: boolean;
  onSearch?: () => void;
}

const StyledInput = styled.input`
  padding: 27px 14px;
  font-size: 20px;
  width: 100%;
  border: none;
  font-weight: ${fontWeights.medium};
  background-color: transparent;
  color: ${colors.navy};

  ::placeholder {
    color: ${colors.navy};
    font-weight: ${fontWeights.medium};
  }

  &:focus-visible {
    outline: none;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 13px 23px;
    font-size: 14px;
  }
`;

const Icon = styled.img`
  padding-left: 30px;
`;

function SearchBar({ visible, setVisible, openOnLoad, onSearch }: Props) {
  const [inputValue, setInputValue] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);

  const checkClickTarget = (e: Event) => {
    if (openOnLoad) return null;
    if (e == null || visible == null || setVisible == null) return null;

    const target = e.target as Element;
    if (visible && target && !target.classList.contains("searchObject")) {
      setVisible(false);
    }

    return;
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (inputValue && e.key === "Enter") {
      if (onSearch) {
        onSearch();
      }
      useStore.setState({ currentSearchValue: inputValue });
      navigate("/search-results");
    }

    return;
  };

  useEffect(() => {
    if (openOnLoad && setVisible) {
      setVisible(true);
    }
  }, []);

  useEffect(() => {
    if (visible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [visible]);

  useEventListener("click", checkClickTarget);
  useEventListener("keyup", handleKeyUp);

  return (
    <Container width="100%" backgroundColor="white" padding="5px 0 30px 0" className="searchObject">
      <Container
        width="95%"
        margin="0 auto"
        backgroundRGBColor={colorsRGB.navy(0.06)}
        borderRadius={10}
      >
        <Icon src={assets.search} className="searchObject" />
        <StyledInput
          ref={inputRef}
          placeholder="Search"
          className="searchObject"
          onChange={e => setInputValue(e.target.value)}
        />
      </Container>
    </Container>
  );
}

export default SearchBar;
