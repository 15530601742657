import React, { useState } from "react";
import styled from "styled-components";
import JotformEmbed from "react-jotform-embed";

import { useFooterQuery } from "@api";
import { Container, TextButton } from "@util/standard";
import { Link, CtaBlock, ConfirmationModal } from "@global";
import { useStore } from "@state/store";
import { colors, fontWeights, TABLET_BREAKPOINT } from "@util/constants";
import { checkIsMicroSite } from "@util/helper";
import { useNavigateOnLogout } from "@util/hooks";

interface Props {
  hideCtas?: boolean;
}

const MenuTitle = styled.h4`
  margin: 0 0 20px 0;
  width: max-content;
  color: ${colors.white};
  font-weight: ${fontWeights.medium};
`;

const formEmbedUrl = `https://form.jotform.com/${process.env.GATSBY_FOOTER_FORM_ID}`;

const LinksWrapper = styled(Container)`
  flex-direction: column;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }
`;

const StyledLink = styled(Link)`
  margin: 0 0 25px 0;
  color: ${colors.white};
  font-weight: ${fontWeights.normal};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 50%;
  }
`;

const Footer = ({ hideCtas }: Props) => {
  const footerData = useFooterQuery();
  if (footerData == null) return null;
  const { openLoginDrawer, loggedIn, setLoggedIn } = useStore();
  const [confirmVisible, setConfirmVisible] = useState(false);
  useNavigateOnLogout();

  const handleLogout = () => setLoggedIn(false);

  const handleLogInClick = () => {
    if (loggedIn) {
      setConfirmVisible(true);
      return;
    }
    openLoginDrawer();
  };

  const { menuLinks, footerSocialLinks, micrositeMenuLinks, footerFormText, footerCtas } =
    footerData;

  const isMicroSite = checkIsMicroSite();

  return (
    <>
      {!hideCtas && footerCtas && <CtaBlock data={footerCtas} />}
      <Container backgroundColor="navy">
        <Container
          width="70%"
          margin="auto"
          tabletWidth="100%"
          tabletMargin="100px 20px"
          tabletFlexDirection="column"
        >
          {!isMicroSite &&
            menuLinks?.map((menu, index) => {
              if (menu == null) {
                return null;
              }

              return (
                <Container
                  margin="120px 12% 120px 0"
                  flexDirection="column"
                  tabletMargin="0px 0 30px 0"
                  key={menu._key}
                >
                  <MenuTitle>{menu.menuTitle}</MenuTitle>

                  <LinksWrapper>
                    {menu.menuLinks &&
                      menu.menuLinks.map(link => {
                        if (link == null) {
                          return null;
                        }

                        return <StyledLink {...link} key={link._key} />;
                      })}
                    {index === 1 && (
                      <TextButton
                        underlined
                        color="white"
                        fontWeight="bold"
                        onClick={handleLogInClick}
                      >
                        {loggedIn ? "Log out" : "Log in"}
                      </TextButton>
                    )}
                  </LinksWrapper>
                </Container>
              );
            })}
          {isMicroSite &&
            micrositeMenuLinks?.map(menu => {
              if (menu == null) {
                return null;
              }

              return (
                <Container
                  margin="120px 12% 120px 0"
                  flexDirection="column"
                  tabletMargin="0px 0 30px 0"
                  key={menu._key}
                >
                  <MenuTitle>{menu.menuTitle}</MenuTitle>

                  <LinksWrapper>
                    {menu.menuLinks &&
                      menu.menuLinks.map(link => {
                        if (link == null) {
                          return null;
                        }

                        return <StyledLink {...link} key={link._key} />;
                      })}
                  </LinksWrapper>
                </Container>
              );
            })}
          <Container width="100%" margin="95px 0 0 0" flexDirection="column" maxWidth="465px">
            <h4 style={{ color: `${colors.white}`, margin: "26px 0 0 0" }}>{footerFormText}</h4>
            <JotformEmbed src={formEmbedUrl} />
            <Container margin="20px 0 50px 0">
              {footerSocialLinks &&
                footerSocialLinks.map(link => {
                  if (link == null) return null;
                  const { socialLink } = link;

                  return (
                    <Container margin="0 20px 0 0" key={link._key}>
                      <a href={socialLink?.link?.externalLink as string} target="__blank">
                        {socialLink?.image && <img src={socialLink?.image?.asset?.url as string} />}
                      </a>
                    </Container>
                  );
                })}
            </Container>
          </Container>
        </Container>
      </Container>
      <ConfirmationModal
        message="Are you sure you would like to logout?"
        onConfirm={handleLogout}
        state={[confirmVisible, setConfirmVisible]}
      />
    </>
  );
};

export default Footer;
